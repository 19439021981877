import React from 'react'

const Actions = {
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  INCREMENT: 'INCREMENT',
  DECREMENT: 'DECREMENT',
  UPDATE_DISCOUNT_MULTIPLIER: 'UPDATE_DISCOUNT_MULTIPLIER',
  CLEAR_CART: 'CLEAR_CART',
}

const PRODUCT_SCHEMA = {
  id: '',
  quantity: 0,
}

const defaultState = {
  products: [], discountCode: '', discountMultiplier: 1, totalProductAmount: 0,
}

function roundTwoDecimals(value) {
  return Math.round(value * 100) / 100
}

function getTotalProductAmount(products) {
  return products.reduce((acc, product) => acc + product.quantity, 0)
}

function reducer(state, action) {
  switch (action.type) {
    case Actions.UPDATE_PRODUCT: {
      const { quantity, id } = action.payload.product
      let products = [...state.products]
      if (quantity > 0) {
        const index = products.findIndex((product) => product.id === id)
        if (index >= 0) {
          products[index].quantity = Number(quantity)
        } else {
          products.push(action.payload.product)
        }
      } else {
        products = products.filter((product) => product.id !== id)
      }
      return {
        ...state,
        products,
        totalProductAmount: getTotalProductAmount(products),
      }
    }
    case Actions.INCREMENT: {
      const { id } = action.payload.product
      const products = [...state.products]
      const index = products.findIndex((product) => product.id === id)
      if (index >= 0) {
        products[index].quantity += 1
      }
      return {
        ...state,
        products,
        totalProductAmount: getTotalProductAmount(products),
      }
    }
    case Actions.DECREMENT: {
      const { id } = action.payload.product
      const products = [...state.products]
      const index = products.findIndex((product) => product.id === id)
      if (index >= 0 && state.products[index].quantity > 1) {
        products[index].quantity += -1
      }
      return {
        ...state,
        products,
        totalProductAmount: getTotalProductAmount(products),
      }
    }
    case Actions.UPDATE_DISCOUNT_MULTIPLIER:
      return {
        ...state,
        discountCode: action.payload.discountCode,
        discountMultiplier: action.payload.discountMultiplier,
      }
    case Actions.CLEAR_CART:
      return defaultState
    default:
      return state
  }
}

const CartContext = React.createContext(defaultState)
function CartProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, JSON.parse(localStorage.getItem('cartGartners')) || defaultState)
  React.useEffect(() => {
    localStorage.setItem('cartGartners', JSON.stringify(state))
  }, [state])
  return (
    <CartContext.Provider value={[state, dispatch]}>
      {children}
    </CartContext.Provider>
  )
}

function updateProduct({ dispatch, id, quantity }) {
  dispatch({
    type: Actions.UPDATE_PRODUCT,
    payload: { product: { ...PRODUCT_SCHEMA, id, quantity } },
  })
}

function increment({ dispatch, id }) {
  dispatch({
    type: Actions.INCREMENT,
    payload: { product: { ...PRODUCT_SCHEMA, id } },
  })
}

function decrement({ dispatch, id }) {
  dispatch({
    type: Actions.DECREMENT,
    payload: { product: { ...PRODUCT_SCHEMA, id } },
  })
}

function calculateTotalPrice(products, discountMultiplier) {
  const originalTotalPrice = products.reduce((totalPrice, { quantity, price }) => totalPrice + (Number(price) * quantity), 0)
  const discountedTotalPrice = originalTotalPrice * discountMultiplier
  const discountedAmount = originalTotalPrice - discountedTotalPrice
  return {
    totalPrice: roundTwoDecimals(discountedTotalPrice),
    discountedAmount: roundTwoDecimals(discountedAmount),
  }
}

function getTotalQuantity(products) {
  return products.reduce((totalQuantity, { quantity }) => totalQuantity + quantity, 0)
}

function updateDiscountMultiplier({ dispatch, discountCode, discountMultiplier }) {
  dispatch({
    type: Actions.UPDATE_DISCOUNT_MULTIPLIER,
    payload: { discountCode, discountMultiplier },
  })
}

function clearCart({ dispatch }) {
  dispatch({
    type: Actions.CLEAR_CART,
  })
}

export {
  CartProvider, updateProduct, calculateTotalPrice, getTotalQuantity, updateDiscountMultiplier, clearCart, increment, decrement,
}
export default CartContext
