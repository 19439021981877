import React, {
  useState, useEffect, useContext,
} from 'react'
import styled from 'styled-components/macro'
import { useMediaQuery } from 'react-responsive'
import FsLightbox from 'fslightbox-react'
import usePixel from '../Hooks/usePixel'
import Button from '../Components/Button'
import GoBackButton from '../Components/GoBackButton'
import ExpandableText from '../Components/ExpandableText'
import useContent from '../Hooks/useContent'
import CartContext, { updateProduct } from '../Context/Cart.Context'
import createProductLink from '../Utils/createProductLink'
import maxDevice, { minDevice } from '../Style/deviceSize'
import ProductMicrodata from '../Components/ProductMicrodata'
import queryBuilder from '../Utils/querybuilder'
import CrossButton from '../Components/CrossButton'
import ContentSkeleton from '../Components/ContentSkeleton'

const Container = styled.div`
  display: flex;
  flex-direction: row;

  @media ${maxDevice.tablet} {
    flex-direction: column;
  }  
`

const LeftSection = styled.div`
  position: relative;
  background-color: #F7F7F7;
  padding: 24px 0 0px 24px;
  width: 55%;
  height: 900px;
  display: flex;
  flex-direction: column;

  @media ${maxDevice.laptopL} {
    padding: 20px 0px 70px 20px;
  }  
  @media ${maxDevice.tablet} {
    padding: 0px;
    width:100%;
    height:60%;
  }  
`

const PictureContainer = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  align-items:center;

  @media ${maxDevice.tablet} {
    flex-direction: column;
  }  
  
  // Hides full screen button in image modal
  .fslightbox-toolbar-button:nth-child(1) { 
    display: none; 
  }
`

const MainImage = styled.img`
  /* margin-right: 18px; */
  width: 90%;
  max-width: 600px;

  @media ${maxDevice.tablet} {
    margin-right: 0px;
  }  
`

const MainImageSkeleton = styled.div`
  /* margin-right: 18px; */
  width: 90%;
  max-width: 600px;
  display: flex;
  justify-content: center;

  @media ${maxDevice.tablet} {
    margin-right: 0px;
  }  
`

const SmallPicturesContainer = styled.div`
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 12px;

  @media ${maxDevice.tablet} {
    width: 100%;
  }  
`

const SmallPicture = styled.img`
  cursor: pointer;
  width: 100%;
    padding-right: 12px;


  @media ${maxDevice.tablet} {
    width: calc(100% / 3);
    height: calc(100%/3);
    margin-bottom: 15px;
    &:not(:first-of-type) {
      margin-top: 0px;
    }   
  }
`

const SmallPictureSkeleton = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  &:not(:last-of-type) {
    margin-right: 30px;
  }

  @media ${maxDevice.tablet} {
    width: calc(100% / 3);
    /* height: calc(100%/3); */
    margin-bottom: 15px;
    &:not(:first-of-type) {
      margin-top: 0px;
    }   
  }
`

const RightSection = styled.div`
  width: 45%;
  margin: 5px 40px;
  text-align: center;
  display: flex;
  flex-direction: column;

  @media ${minDevice.tablet} {
    border-top: thin solid lightgray;
  }

  @media ${maxDevice.tablet} {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }  
`

const RightSubSection = styled.div`
  width: 80%;
  margin: 0 auto;
  padding-top: 40px;
  &:not(:last-of-type) {
    padding-bottom: 45px;
    border-bottom: 1px solid lightgray;
  }
`

const Title = styled.h2`
  font-size: 30px;
  margin-bottom: 0;
`
const Price = styled.p`
  font-size: 18px;
`

const Composition = styled.p`
  margin-top: 0;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: row;
  justify-content: center;
`

const BigButton = styled(Button)`
  margin-right: 10px;
`

const QuantityInput = styled.input`
  border: solid 1px black;
  text-align: center;
  width: 50px;
  padding-left: 10px;
  font-size: 18px;

  @media ${maxDevice.tablet} {
    padding-left: 0px;
  }
`
const NotInStock = styled.div`
  width: 50%;
  color: red;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
`

const StyledCrossButton = styled(CrossButton)`
  margin: 14px 19px 0 auto;
`

function getCurrentQuantity(id, cartState) {
  return cartState.products.reduce((totalQuantity, cartProduct) => {
    if (cartProduct.id === id) return totalQuantity + cartProduct.quantity
    return totalQuantity
  }, 0)
}

function ProductPage({ location, match: { params: { id } } }) {
  const { addToCart: pixelAddToCart } = usePixel()
  const { products, L, TRANSLATION_KEYS: { BUTTON, PRODUCT_INFO } } = useContent()
  const { viewContent } = usePixel()
  const [cartState, cartDispatch] = useContext(CartContext)
  const [product, setProduct] = useState(null)
  const [mainImage, setMainImage] = useState(0)
  const [imageModalToggler, setImageModalToggler] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const isBiggerThanTablet = useMediaQuery({
    query: minDevice.tablet,
  })

  useEffect(() => {
    if (products) {
      const foundProduct = products
        .find(({ id: productId }) => String(productId) === id)
      setProduct(foundProduct)
      setMainImage(foundProduct.imageUrls[0])
    }
  }, [id, products, location.pathname])

  useEffect(() => {
    if (!product) return
    viewContent(product.id, product.price)
  }, [product, viewContent])

  const addToCart = () => {
    pixelAddToCart(Array(quantity).fill(product.id), product.price * quantity)
    updateProduct({
      dispatch: cartDispatch,
      id: product.id,
      quantity: Number(quantity) + getCurrentQuantity(product.id, cartState),
    })
  }

  const toggleImageModal = () => {
    setImageModalToggler((prevState) => !prevState)
  }

  const onQuantityChange = (e) => {
    setQuantity(e.target.value)
  }

  return (
    <Container id="product-page-container">
      {product && (
        <ProductMicrodata
          id={product.id}
          name={product.name}
          description={product.description}
          url={createProductLink(product.type, product.id, true)}
          imageUrl={product.imageUrls[0]}
          price={product.price}
          inStock={product.inStock}
        />
      )}
      <LeftSection>
        {isBiggerThanTablet
          ? <GoBackButton size="small" />
          : <StyledCrossButton />}
        <PictureContainer>
          {product
            ? <MainImage src={queryBuilder(mainImage, { queries: { h: 800, w: 800 } })} alt="scarf" onClick={toggleImageModal} />
            : (
              <MainImageSkeleton>
                <ContentSkeleton skeletons={[{ width: 300, height: 300, marginTop: 40 }]} />
              </MainImageSkeleton>
            )}
          {product && (
            <FsLightbox
              loadOnlyCurrentSource
              toggler={imageModalToggler}
              sources={
                [queryBuilder(mainImage, { queries: { fl: 'progressive' } })]
              }
              key={mainImage}
            />
          )}

          <SmallPicturesContainer>
            {product
              ? product?.imageUrls.map((url, index) => (
                <SmallPicture
                  src={queryBuilder(url, { queries: { h: 800, w: 800 } })}
                  alt="scarf"
                  onClick={() => { setMainImage(product.imageUrls[index]) }}
                  key={url}
                />
              ))
              : new Array(3).fill(null).map((item, index) => (
                <SmallPictureSkeleton key={`smallPicSkeleton-${index}`}>
                  <ContentSkeleton skeletons={[{ width: 75, height: 75 }]} />
                </SmallPictureSkeleton>
              ))}
          </SmallPicturesContainer>
        </PictureContainer>
      </LeftSection>

      <RightSection>
        <RightSubSection>
          <Title><ContentSkeleton skeletons={[{ height: 40, width: 250 }]}>{product?.name}</ContentSkeleton></Title>
          <Price><ContentSkeleton skeletons={[{ width: 100 }]}>{`${product?.price.toFixed(2)} kr`}</ContentSkeleton></Price>
        </RightSubSection>
        <RightSubSection>
          <Composition>
            <ContentSkeleton skeletons={[{ width: 350, height: 30 }]}>{L(PRODUCT_INFO.COMPOSITION)}</ContentSkeleton>
          </Composition>
          {product && !product.inStock
            ? (
              <ButtonContainer>
                <NotInStock>
                  {L(PRODUCT_INFO.NOT_IN_STOCK)}
                </NotInStock>
              </ButtonContainer>
            )
            : (
              <ButtonContainer>
                <BigButton onClick={addToCart}>
                  {L(BUTTON.ADD_TO_CART)}
                </BigButton>
                <ContentSkeleton>
                  <QuantityInput type="number" min="0" max="99" defaultValue={quantity} onChange={onQuantityChange} />
                </ContentSkeleton>
              </ButtonContainer>
            )}
        </RightSubSection>
        <RightSubSection>
          <ContentSkeleton skeletons={[{ height: 30 }]}>
            <ExpandableText
              content={{
                [L(PRODUCT_INFO.DETAILS)]: product?.description,
                [L(PRODUCT_INFO.CARE)]: L(PRODUCT_INFO.CARE_INFO),
              }}
            >
              {L(PRODUCT_INFO.DETAILS_AND_CARE)}
            </ExpandableText>
          </ContentSkeleton>
          <ContentSkeleton skeletons={[{ height: 30, marginTop: 5 }]}>
            <ExpandableText
              content={{
                [L(PRODUCT_INFO.SIZE)]: product?.size,
                [L(PRODUCT_INFO.FIT)]: 'One size',
              }}
            >
              {L(PRODUCT_INFO.SIZE_AND_FIT)}
            </ExpandableText>
          </ContentSkeleton>
          <ContentSkeleton skeletons={[{ height: 30, marginTop: 5 }]}>
            <ExpandableText
              content={{
                [L(PRODUCT_INFO.SHIPPING)]: L(PRODUCT_INFO.SHIPPING_INFO),
              }}
            >
              {L(PRODUCT_INFO.SHIPPING_AND_RETURNS)}
            </ExpandableText>
          </ContentSkeleton>
        </RightSubSection>
      </RightSection>
    </Container>
  )
}

export default ProductPage
