import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import maxDevice from '../Style/deviceSize'

const Iframe = styled.iframe`
  border: none;
  width: 100%;

  @media ${maxDevice.tablet}{
    margin-bottom: 10px;
  }
`

function KlarnaIframe({ onLoad: outerOnLoad, srcDoc }) {
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const [iframeHeight, setIframeHeight] = useState()
  const iframeRef = useRef()

  const observer = useRef(
    new ResizeObserver((entries) => {
      const { height } = entries[0].contentRect
      // For some reason an additional 20px is necessary to get rid of overflow
      setIframeHeight(`${height + 20}px`)
    }),
  )

  useEffect(() => {
    if (iframeLoaded) {
      const innerIframe = iframeRef?.current?.contentWindow?.document?.getElementById('klarna-checkout-iframe')
      const scopedObserver = observer.current
      if (innerIframe) { scopedObserver.observe(innerIframe) }
      return () => { scopedObserver.disconnect() }
    }
    return () => { }
  }, [iframeLoaded])

  const onLoad = () => {
    setIframeLoaded(true)
    if (outerOnLoad) outerOnLoad(iframeRef)
  }

  return (
    <Iframe
      srcDoc={srcDoc}
      ref={iframeRef}
      onLoad={onLoad}
      height={iframeHeight}
      scrolling="no"
      allowpaymentrequest="true"
    />
  )
}

export default KlarnaIframe
