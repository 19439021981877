function queryBuilder(url, {
  paths = [], queries = {},
}) {
  let build = url
  if (paths.length) {
    if (!build.endsWith('/')) build += '/'
    build += `${paths.join('/')}/`
  }
  if (Object.keys(queries).length) {
    const queryString = Object.entries(queries)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    build += `?${queryString}`
  }
  return build
}

export default queryBuilder
