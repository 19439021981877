import { useEffect, useState, useContext } from 'react'
import LanguageContext from '../Context/Language.Context'
import ContentContext, { LoadingState } from '../Context/Content.Context'
import extractFromContent from '../Utils/extractFromContent'
import TRANSLATION_KEYS from '../Utils/translationKeys'

function useContent() {
  const { languageCode } = useContext(LanguageContext)
  const { loadingState, content } = useContext(ContentContext)
  const [products, setProducts] = useState(null)
  const [translationKeys, setTranslationKeys] = useState({})
  const contentReady = loadingState === LoadingState.DONE

  const getText = (key, { injections } = {}) => {
    const translation = translationKeys[key]
    if (!translation) return key
    if (!injections) return translation
    const replacements = translation.match(/{([^}]*)}/g)
    return replacements
      .reduce((acc, match, index) => acc.replace(match, injections[index]), translation)
  }

  useEffect(() => {
    if (contentReady) {
      const languageContent = extractFromContent(content, languageCode)
      setProducts(languageContent.products)
      setTranslationKeys(languageContent.translationKeys)
    }
  }, [languageCode, content, loadingState, contentReady])

  return {
    L: getText,
    products,
    loadingState,
    TRANSLATION_KEYS,
  }
}

export default useContent
