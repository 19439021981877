import React from 'react'
import {
  BrowserRouter,
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ScrollToTop from './Pages/ScrollToTop'
import GlobalStyle from './Style/Globalstyle'
import Routes from './Routes'
import usePixel from './Hooks/usePixel'
import { LanguageProvider } from './Context/Language.Context'
import { ContentProvider } from './Context/Content.Context'
import { CartProvider } from './Context/Cart.Context'

function App() {
  const { initiate } = usePixel()
  if (window.location.hostname !== 'localhost') {
    initiate()
  }
  const renderMetadata = () => {
    if (process.env.REACT_APP_ENVIRONMENT_TYPE === 'development') {
      return <Helmet><meta name="robots" content="noindex" /></Helmet>
    }
    return null
  }
  return (
    <>
      {renderMetadata()}
      <LanguageProvider>
        <ContentProvider>
          <CartProvider>
            <BrowserRouter>
              <GlobalStyle />
              <ScrollToTop>
                <Routes />
              </ScrollToTop>
            </BrowserRouter>
          </CartProvider>
        </ContentProvider>
      </LanguageProvider>
    </>
  )
}

export default App
