import React, { useEffect, Fragment } from 'react'
import { withRouter } from 'react-router-dom'

function ScrollToTop({ history, children }) {
  useEffect(() => {
    let routingFromHomepage = history.location.pathname === '/'
    let homePageLocation = null
    const unlisten = history.listen((location) => {
      const doc = document.documentElement
      const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
      if (routingFromHomepage) {
        homePageLocation = top
        routingFromHomepage = false
      }

      if (location.pathname === '/') {
        routingFromHomepage = true
        if (homePageLocation) {
          // Necessary timeout for page to render content before scrolling
          setTimeout(() => window.scrollTo({ top: homePageLocation }), 16)
        }
      } else if (top > 250) {
        window.scrollTo({ top: 70 })
      }
    })
    return () => {
      unlisten()
    }
  }, [history])

  return <Fragment>{children}</Fragment>
}

export default withRouter(ScrollToTop)
