import React from 'react'
import Microdata from './Microdata'
import queryBuilder from '../Utils/querybuilder'

function ProductMicrodata({
  id, name, description, url, imageUrl, price, inStock,
}) {
  if ([id, name, description, url, imageUrl, price, inStock]
    .some((value) => value === undefined)) return null

  return (
    <Microdata
      title={name}
      description={description}
      url={url}
      imageUrl={queryBuilder(imageUrl, { queries: { h: 1200, w: 627, fit: 'thumb' } })}
      product={{
        id,
        name,
        price,
        inStock,
        category: '543673',
      }}
    />
  )
}

export default ProductMicrodata
