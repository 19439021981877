import createUrl from './createUrl'

async function send({
  headers,
  host,
  endpoint,
  body = null,
  query = {},
}) {
  const setup = {
    headers,
    method: endpoint.method,
    mode: 'cors',
  }

  if (body) {
    setup.body = typeof body === 'string' ? body : JSON.stringify(body)
  }

  const url = createUrl({
    prefix: host.includes('localhost') ? 'http://' : 'https://',
    host,
    endpoint,
    params: query,
  })

  try {
    const response = await fetch(url, setup)

    if (!response.ok) {
      throw new Error(
        `Response contains an unsuccessful response code: ${response.status}`,
      )
    }

    const formattedResponse = await response.json()
    return formattedResponse
  } catch (error) {
    console.error('Error in fetch', { error, endpoint })
    throw error
  }
}

export default send
