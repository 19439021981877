import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import Cart from '../Components/Cart'
import LanguageSelector from '../Components/LanguageSelector'
import maxDevice, { minDevice } from '../Style/deviceSize'

const Container = styled.div`
  height: 170px;

  @media ${maxDevice.tablet} {
    height: 50px; 
  }
`

const FixedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 99;
  background-color: white;
  position: fixed;


  @media ${minDevice.tablet} {
    margin-bottom: 25px;
    position: ${({ scrollPosition }) => (scrollPosition > 10 ? 'fixed' : 'auto')};
  }
`

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: ${({ scrollPosition }) => (scrollPosition > 10 ? '80px' : '150px')};
  
  @media ${maxDevice.tablet} {
    height: 50px;
  }
`

const Section = styled.div`
  width: calc(100% / 3);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 50;
  
  @media ${maxDevice.tablet} {
    width: 20%;
    :nth-of-type(2) {
      width: 60%;
    }
    @media ${maxDevice.mobileL} {
      :first-of-type {
      justify-content: start;
      padding-left: 10px;
    }
      :last-of-type {
        justify-content: flex-end;
        padding-right: 20px;
      }
    }
  }
`

const Logo = styled.div`
  background-image: url("/bigLogo.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  min-width: ${({ scrollPosition }) => (scrollPosition > 10 ? '200px' : '420px')};
  min-height: ${({ scrollPosition }) => (scrollPosition > 10 ? '80px' : '150px')};
  z-index: 99;

  @media ${maxDevice.tablet} {
    min-width: 150px; 
    min-height: 60px;
  }
`

function Header() {
  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = () => {
    setScrollPosition(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => { window.removeEventListener('scroll', handleScroll) }
  }, [])

  return (
    <Container scrollPosition={scrollPosition}>
      <FixedContainer scrollPosition={scrollPosition}>
        <Row scrollPosition={scrollPosition}>
          <Section>
            <LanguageSelector />
          </Section>
          <Section>
            <Link to="/">
              <Logo scrollPosition={scrollPosition} />
            </Link>
          </Section>
          <Section>
            <Cart />
          </Section>
        </Row>
      </FixedContainer>
    </Container>
  )
}

export default Header
