import React from 'react'
import styled from 'styled-components/macro'
import Input from '../Components/Input'
import InputBox from '../Components/InputBox'
import maxDevice from '../Style/deviceSize'
import Button from '../Components/Button'
import GoBackButton from '../Components/GoBackButton'
import useContent from '../Hooks/useContent'
import Microdata from '../Components/Microdata'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 100px;
  padding-top: 50px;
  justify-content: center;

  @media ${maxDevice.laptop}{
    padding:10px;
  }

  @media ${maxDevice.tablet}{
    flex-direction: column;
  }
`

const SmallContainer = styled.div`
  display: flex;
  flex-direction: column;
  width:45%;
  max-width:430px;
  align-self: center;

  @media ${maxDevice.tablet}{
    padding: 0px;
    width: 100%;
  }
`
const PlaceHolderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media ${maxDevice.tablet}{
  width: 100%;
} 
`

const Title = styled.h2`
  font-size: 36px;
`

const PictureArea = styled.div`
  background-image: url(phone.webp);
  background-size: no-repeat;
  background-position: center;
  margin: 20px;
  width: 455px;
  height: 420px;

  @media ${maxDevice.tablet}{
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 400px;
    border-radius: 30px;
  } 
`
const ShortName = styled(Input)`
  width: 45%;
  margin-left: 0px;
  margin-right: 0px;
  text-align: left;
`

const LongName = styled(Input)`
width: 100%;
margin-left: 0px;
text-align: left;

  @media ${maxDevice.tablet}{
    width: 100%;
  }
`
const Message = styled(InputBox)`
height: 50%;
margin-left: 0px;
width: 100%;

  @media ${maxDevice.tablet}{
    height: 150px;
    width: 100%;
  }
`
const ButtonContainer = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;

  @media ${maxDevice.tablet}{
    margin-bottom: 25px;
  }
`

function ContactUs() {
  const { L, TRANSLATION_KEYS: { CONTACT_US, BUTTON } } = useContent()
  return (
    <Container>
      <Microdata
        title={L(CONTACT_US.MICRO_TITLE)}
        description={L(CONTACT_US.MICRO_DESCRIPTION)}
        url={`https://${process.env.REACT_APP_FRONTEND_HOST}/contact-us`}
      />
      <SmallContainer>
        <GoBackButton size="small" />
        <Title>{L(CONTACT_US.TITLE)}</Title>
        <form action="https://formspree.io/xrgevdgr" method="POST">
          <PlaceHolderRow>
            <ShortName type="text" required name="first name" placeholder={L(CONTACT_US.FIRSTNAME)} />
            <ShortName type="text" required name="last name" placeholder={L(CONTACT_US.LASTNAME)} />
          </PlaceHolderRow>
          <LongName type="email" required name="email" placeholder={`${L(CONTACT_US.EMAIL)}*`} />
          <LongName name="subject" required placeholder={L(CONTACT_US.SUBJECT)} />
          <Message name="message" required placeholder={L(CONTACT_US.MESSAGE)} />
          <ButtonContainer>
            <Button color="white">{L(BUTTON.SUBMIT)}</Button>
          </ButtonContainer>
        </form>
      </SmallContainer>

      <PictureArea />
    </Container>
  )
}

export default ContactUs
