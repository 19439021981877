import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import Skeleton from 'react-loading-skeleton'
import { useMediaQuery } from 'react-responsive'
import useSkeleton from '../Hooks/useSkeleton'
import Picture from './Picture'
import maxDevice, { minDevice } from '../Style/deviceSize'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 6px;
  height: 380px;
  max-width: 300px;
  @media ${maxDevice.tablet} {
    height: 420px;
    max-width: 320px;
  }
`

const ImageSkeleton = styled.div`
  padding: 30px;
  width: 300px;
  min-height: 300px;
`

const ImageBoxWrapper = styled.div` 
  position: relative;
  
  picture :nth-child(2) {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s ease-in;
    position: absolute;
    opacity: 0;
  }

  :hover picture :nth-child(2) {
    opacity: 1; 
  }

  @media ${minDevice.tablet} {
    :hover picture :first-child {
      transition: opacity 0.3s ease-in;
      opacity: 0; 
    }
  }

`

const ImageBox = styled.img`
  width: 300px;
  min-height: 300px;
  @media ${maxDevice.tablet} {
    max-width: 320px;
    min-height: 320px;
  }
`

const TextBox = styled.p`
  margin: 10px 0 0 0;
  color: black;
  text-align: center;
  padding: 0px;
`

function ProductCard({
  name, price, link = '', imageUrl, hoverImageUrl,
}) {
  const { ContentSkeleton } = useSkeleton()
  const laptopOrBigger = useMediaQuery({
    query: minDevice.laptop,
  })
  return (
    <Link to={link}>
      <Container>
        <div>
          {imageUrl
            ? (
              <ImageBoxWrapper>
                <Picture src={imageUrl} height={500} width={500} ImageComponent={ImageBox} />
                {laptopOrBigger && <Picture src={hoverImageUrl} height={500} width={500} ImageComponent={ImageBox} />}
              </ImageBoxWrapper>
            )
            : <ImageSkeleton><Skeleton height={230} count={1} /></ImageSkeleton>}
          <TextBox>
            <ContentSkeleton skeletons={[{ width: 200 }]}>
              {name}
            </ContentSkeleton>
          </TextBox>
        </div>
        <div>
          <TextBox>
            <ContentSkeleton skeletons={[{ width: 75 }]}>
              {`${price && price.toFixed(2)} kr`}
            </ContentSkeleton>
          </TextBox>
        </div>
      </Container>
    </Link>
  )
}

export default ProductCard
