import React from 'react'
import styled from 'styled-components/macro'
import maxDevice from '../Style/deviceSize'
import GoBackButton from '../Components/GoBackButton'
import useContent from '../Hooks/useContent'
import Markdown from '../Components/Markdown'
import Microdata from '../Components/Microdata'

const BigContainer = styled.div`
  padding: 75px;
  width: 100%;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;

  @media ${maxDevice.tablet}{
    padding: 0px;
  } 
`
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${maxDevice.tablet}{
    display:none;
  }
`
const TextContainer = styled.div`
  width: 85%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 75px;

  @media ${maxDevice.tablet}{
    margin-left: 0px;
    text-align:center;
    width:100%;
    padding:25px;
  }
`
const Heading = styled.h1`
  margin-top: 0px;
`
const Textspace = styled.h3`
  margin-top: 10px;
`
const Text = styled.p`
  margin-top: 0px;
`

const GoBackButtonWrap = styled(GoBackButton)`

@media ${maxDevice.tablet}{
  display: none;
}
`

function TermsAndCondition() {
  const { L, TRANSLATION_KEYS: { TERMS } } = useContent()
  return (
    <BigContainer>
      <Microdata
        title={L(TERMS.MICRO_TITLE)}
        description={L(TERMS.MICRO_DESCRIPTION)}
        url={`https://${process.env.REACT_APP_FRONTEND_HOST}/terms-and-conditions`}
      />
      <LeftContainer>
        <GoBackButtonWrap size="small" />
      </LeftContainer>
      <TextContainer>
        <Markdown
          Heading1={Heading}
          Heading3={Textspace}
          Paragraph={Text}
        >
          {L(TERMS.MARKDOWN)}
        </Markdown>
      </TextContainer>

    </BigContainer>
  )
}

export default TermsAndCondition
