// *****************************************************************
// *****************************************************************
// ********* DEPRECATED - USE ContentSkeleton.js instead ***********

import React from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import { LoadingState } from '../Context/Content.Context'
import useContent from './useContent'

const StyledSkeleton = styled(Skeleton)`
  margin-top: ${(({ marginTop }) => (marginTop ? `${marginTop}px` : 'initial'))};
  margin-right: ${(({ marginRight }) => (marginRight ? `${marginRight}px` : 'initial'))};
`

function useSkeleton() {
  const { loadingState } = useContent()

  const ContentSkeleton = ({ children, skeletons = [{}] } = {}) => {
    if (loadingState !== LoadingState.DONE) {
      return skeletons.map((args, index) => <StyledSkeleton {...args} key={`skeleton-${index}-${JSON.stringify(args)} `} />)
    }
    return children || null
  }

  return { ContentSkeleton }
}

export default useSkeleton
