import React, { useState } from 'react'
import styled from 'styled-components'
import Button from './Button'
import Input from './Input'
import maxDevice from '../Style/deviceSize'
import useDiscount, { ATTITUDES } from '../Hooks/useDiscount'

const DiscountContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0 0 0 0;
  padding-top:54px;`


const DiscountInput = styled(Input)`
text-align: center;
margin-bottom: 0;

@media ${maxDevice.tablet}{
max-width: 170px;
}
`
const DiscountMessage = styled.p`
  font-size: 13px;
  text-align: center;
  color: ${({ color }) => color};
  margin: 5px auto 20px auto;
  max-width: 220px;  
`

const DiscountText = styled.span`
margin-bottom: 5px;
`
const VerifyOrRemoveButton = styled(Button)`
  position: absolute;
  right: 0;


  @media ${maxDevice.tablet}{
  margin-left:10px;
  position:relative;
}
`


function CartDiscount({ cartState, CART, L }) {
  const [discountCode, setDiscountCode] = useState('')
  const {
    getDiscount, removeDiscount, loading, message,
  } = useDiscount()


  return (
    <>
      <DiscountContainer>
        {cartState.discountMultiplier !== 1
          ? <DiscountText><b>{cartState.discountCode}</b></DiscountText>
          : (
            <DiscountInput
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
              onKeyDown={(e) => { if (e.key === 'Enter') getDiscount(discountCode) }}
              borderColor="black"
              width="220px"
              placeholder={L(CART.DISCOUNT_CODE)}
            />
          )}

        {cartState.discountMultiplier !== 1
          ? (
            <VerifyOrRemoveButton
              size="small"
              onClick={() => { setDiscountCode(''); removeDiscount() }}
            >
              {L(CART.REMOVE)}
            </VerifyOrRemoveButton>
          )
          : (
            <VerifyOrRemoveButton
              size="small"
              onClick={() => getDiscount(discountCode)}
              isLoading={loading}
              disabled={loading}
            >
              {L(CART.VERIFY)}
            </VerifyOrRemoveButton>
          )}
      </DiscountContainer>
      <DiscountMessage color={message.attitude === ATTITUDES.HAPPY ? 'green' : 'red'}>
        {L(message.key, { injections: message.injections })}
      </DiscountMessage>
    </>
  )
}
export default CartDiscount