import React from 'react'
import styled from 'styled-components/macro'

const InputField = styled.input`
  width: ${({ width }) => (width || '385px')};
  border: none;
  border-bottom: ${({ borderColor }) => `1px solid ${borderColor || 'lightgray'}`};
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-size: 13px;
  resize: none;

  &:focus {
    &::-webkit-input-placeholder {
      color: transparent;
      -webkit-transition: color 0.2s ease;
      transition: color 0.2s ease;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: transparent;
      -webkit-transition: color 0.2s ease;
      transition: color 0.2s ease;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: transparent;
      -webkit-transition: color 0.2s ease;
      transition: color 0.2s ease;
    }

    &:-ms-input-placeholder {
      color: transparent;
      -webkit-transition: color 0.2s ease;
      transition: color 0.2s ease;
    }
  }
`

function Input({
  children, width, borderColor, ...props
}) {
  return (
    <InputField width={width} borderColor={borderColor} {...props}>{children}</InputField>
  )
}

export default Input
