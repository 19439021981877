import React from 'react'
import styled, { css } from 'styled-components'

const Loader = styled.div`
  background: transparent !important;
  width: 75px;
  height: 75px;
  border-radius: 100%;
  border: 4px solid;
  display: inline-block;
  animation: clip-rotate 0.75s 0s infinite linear;
  animation-fill-mode: both;
  ${({ size }) => css`
    ${size === 'small' && `
      width: 20px;
      height: 20px;
      border: 2px solid;
    `}
  `}
  border-color: black;
  border-bottom-color: transparent;

  @keyframes clip-rotate {
    0% {transform: rotate(0deg) scale(1)} 
    50% {transform: rotate(180deg) scale(0.8)}
    100% {transform: rotate(360deg) scale(1)}
  }
`

function ClipLoader({ size }) {
  return <Loader size={size} />
}

export default ClipLoader
