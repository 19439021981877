import { createGlobalStyle } from 'styled-components/macro'
import { normalize } from 'polished'

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  @font-face {
    font-family: 'sukhumvit set text';
    src: url('/SukhumvitSet-Text.ttf') format('truetype');
  }
  @font-face {
    font-family: 'sukhumvit set medium';
    src: url('/SukhumvitSet-Medium.ttf') format('truetype');
  }
  @font-face {
    font-family: 'sukhumvit set light';
    src: url('/SukhumvitSet-Light.ttf') format('truetype');
  }
  @font-face {
    font-family: 'sukhumvit set thin';
    src: url('/SukhumvitSet-Thin.ttf') format('truetype');
  }
  @font-face {
    font-family: 'sukhumvit set bold';
    src: url('/SukhumvitSet-Bold.ttf') format('truetype');
  }
  @font-face {
    font-family: 'sukhumvit set semi bold';
    src: url('/SukhumvitSet-SemiBold.ttf') format('truetype');
  }

  * {
    font-family: 'sukhumvit set thin';
    box-sizing: border-box;
    letter-spacing: 0.8px;
  }

  body {
  }

  h1, h2, h3, h4, h5, h6 {
    letter-spacing: 2px;
  }

  h1 {
    font-size: 36px;
  }

  a {
    text-decoration: none; /* no underline */
  }

  div, button, input:focus {
    outline: none;
  }

`
export default GlobalStyle
