import React from 'react'
import queryBuilder from '../Utils/querybuilder'

function Picture({
  ImageComponent = 'img',
  src,
  height,
  width,
  progressive,
  quality,
}) {
  const queries = {
    ...(height && { h: height }),
    ...(width && { w: width }),
    ...(progressive && { fl: 'progressive' }),
    ...(quality && { q: quality }),
  }
  const jpgUrl = queryBuilder(src, { queries: { ...queries, fm: 'jpg' } })
  const webpUrl = queryBuilder(src, { queries: { ...queries, fm: 'webp' } })

  return (
    <picture>
      {!progressive && <source srcSet={webpUrl} type="image/webp" />}
      <ImageComponent src={jpgUrl} type="image/jpg" />
    </picture>
  )
}

export default Picture
