import { useRef } from 'react'
import ReactPixel from 'react-facebook-pixel'

const initiate = () => {
  const options = {
    autoConfig: true,
    debug: process.env.REACT_APP_ENVIRONMENT_TYPE === 'development',
  }
  ReactPixel.init(process.env.REACT_APP_PIXEL_ID, undefined, options)
}

const trackContent = (event, ids, price) => {
  ReactPixel.fbq('track', event, {
    content_type: 'product',
    content_ids: ids.length === 1
      ? String(ids[0])
      : ids.map((id) => String(id)),
    value: price,
    currency: 'SEK',
  })
}

const viewPage = () => { ReactPixel.pageView() }

const viewContent = (id, price) => { trackContent('ViewContent', [id], price) }

const addToCart = (ids, totalPrice) => { trackContent('AddToCart', ids, totalPrice) }

const initiateCheckout = (ids, totalPrice) => { trackContent('InitiateCheckout', ids, totalPrice) }

const purchase = (ids, totalPrice) => { trackContent('Purchase', ids, totalPrice) }

function usePixel() {
  const contentViewed = useRef(false)

  return {
    initiate,
    viewPage,
    addToCart,
    initiateCheckout,
    purchase,
    viewContent: (id, price) => {
      if (contentViewed.current) return
      viewContent(id, price)
      contentViewed.current = true
    },
  }
}

export default usePixel
