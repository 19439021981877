import React, {
  useState, useContext, useEffect,
} from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components/macro'
import CartContext from '../Context/Cart.Context'
import getBackendCommunicator from '../Classes/BackendCommunicator'
import CartItems from '../Components/CartItems'
import maxDevice from '../Style/deviceSize'
import ClipLoader from '../Components/ClipLoader'
import usePixel from '../Hooks/usePixel'
import useContent from '../Hooks/useContent'
import GoBackButton from '../Components/GoBackButton'
import LanguageContext from '../Context/Language.Context'
import Microdata from '../Components/Microdata'
import KlarnaIframe from '../Components/KlarnaIframe'

const backendCommunicator = getBackendCommunicator()

const Container = styled.div`
  min-height: 900px;
  height: 100%;
  display: flex;

  @media ${maxDevice.laptop}{
    padding: 10px;
    flex-direction: column;
  }
`

const MiddleSection = styled.div`
  flex: 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const RightSection = styled.div`
  margin-top: 25px;
  height: 100%;
  flex: 1;
`

const Title = styled.h1`
  margin-bottom: 30px;
`

const CartEmptyMessage = styled.p`
  margin-top: 30px;
  max-width: 400px;
`

const StyledGoBackButton = styled(GoBackButton)`
  margin-left: 5px;
`

const SmallText = styled.div`
  width: 80%;
  color: black;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 20px;
`

function Checkout({ history }) {
  const { initiateCheckout } = usePixel()
  const { L, TRANSLATION_KEYS: { CHECKOUT, SHARED } } = useContent()
  const [klarnaSnippet, setKlarnaSnippet] = useState(null)
  const [cartState] = useContext(CartContext)
  const { languageCode } = useContext(LanguageContext)
  const [showGdpr, setShowGdpr] = useState(false)

  useEffect(() => {
    if (cartState.products.length < 1) return
    const createPayment = async () => {
      const [{ snippet, checkoutContent: { products, totalPrice } }] = await Promise.all([
        backendCommunicator.createKlarnaPayment(languageCode, cartState.products, cartState.discountCode),
        new Promise((resolve) => setTimeout(resolve, 1500)),
      ])
      const ids = products.map(
        ({ id, quantity }) => Array(quantity).fill(id),
      ).flat()
      if (totalPrice && totalPrice > 0) initiateCheckout(ids, totalPrice)
      setKlarnaSnippet(snippet)
    }
    createPayment()
  }, [languageCode, cartState, initiateCheckout])

  const iframeLoad = (iframeRef) => {
    if (!showGdpr) setShowGdpr(true)
    const { pathname, search } = iframeRef.current.contentWindow.location
    if (pathname === '/klarna/confirmation') {
      const urlParams = new URLSearchParams(search)
      const orderId = urlParams.get('klarna-order-id')
      history.push(`/confirmation?order-id=${orderId}`)
    }
  }

  const renderKlarnaFlow = (snippet) => {
    if (!snippet) return <ClipLoader />
    return (
      <>
        {showGdpr && <SmallText>{L(CHECKOUT.DISCLAIMER)}</SmallText>}
        <KlarnaIframe onLoad={iframeLoad} srcDoc={snippet} />
      </>
    )
  }

  const renderStartShoppingFlow = () => (
    <CartEmptyMessage>{L(SHARED.EMPTY_CART)}</CartEmptyMessage>
  )
  return (
    <Container>
      <Microdata
        title={L(CHECKOUT.MICRO_TITLE)}
        description={L(CHECKOUT.MICRO_DESCRIPTION)}
        url={`https://${process.env.REACT_APP_FRONTEND_HOST}/checkout`}
      />
      <StyledGoBackButton size="small" />
      <MiddleSection>
        <Title>{L(CHECKOUT.TITLE)}</Title>
        {cartState.products.length > 0
          ? renderKlarnaFlow(klarnaSnippet)
          : renderStartShoppingFlow()}
      </MiddleSection>
      <RightSection>
        <CartItems minified />
      </RightSection>
    </Container>
  )
}

export default withRouter(Checkout)
