import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import CartContext, { clearCart } from '../Context/Cart.Context'
import ClipLoader from '../Components/ClipLoader'
import BackendCommunicator from '../Classes/BackendCommunicator'
import usePixel from '../Hooks/usePixel'
import useContent from '../Hooks/useContent'
import KlarnaIframe from '../Components/KlarnaIframe'

const Container = styled.div`
  width: 100%;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CartEmptyMessage = styled.p`
  margin-top: 30px;
  max-width: 400px;
`

function Confirmation({ location }) {
  const { purchase } = usePixel()
  const [klarnaSnippet, setKlarnaSnippet] = useState(null)
  const { L, TRANSLATION_KEYS: { SHARED } } = useContent()
  const [, cartDispatch] = useContext(CartContext)
  const urlParams = new URLSearchParams(location.search)
  const orderId = urlParams.get('order-id')

  useEffect(() => {
    if (!orderId) return
    const submitOrder = async (orderID) => {
      const {
        snippet,
        checkoutContent: { products, totalPrice },
      } = await BackendCommunicator().submitOrder(orderID)

      const ids = products.map(
        ({ id, quantity }) => Array(quantity).fill(id),
      ).flat()
      purchase(ids, totalPrice)
      setKlarnaSnippet(snippet)
      clearCart({ dispatch: cartDispatch })
    }
    submitOrder(orderId)
  }, [orderId, cartDispatch, purchase])

  const renderSnippet = () => {
    if (!klarnaSnippet) return <ClipLoader />
    return <KlarnaIframe srcDoc={klarnaSnippet} />
  }

  return (
    <Container>
      {orderId
        ? renderSnippet()
        : <CartEmptyMessage>{L(SHARED.EMPTY_CART)}</CartEmptyMessage>}
    </Container>
  )
}

export default Confirmation
