export default {
  HOMEPAGE: {
    MICRO_TITLE: 'homepage-micro-title',
    MICRO_DESCRIPTION: 'homepage-micro-description',
  },
  CHECKOUT: {
    TITLE: 'checkout-title',
    DISCLAIMER: 'checkout-disclaimer',
    MICRO_TITLE: 'checkout-micro-title',
    MICRO_DESCRIPTION: 'checkout-micro-description',
  },
  PRIVACY: {
    MARKDOWN: 'privacy-markdown',
    MICRO_TITLE: 'privacy-micro-title',
    MICRO_DESCRIPTION: 'privacy-micro-description',
  },
  TERMS: {
    MARKDOWN: 'terms-markdown',
    MICRO_TITLE: 'terms-micro-title',
    MICRO_DESCRIPTION: 'terms-micro-description',
  },
  CONTACT_US: {
    MESSAGE: 'contactus-message',
    SUBJECT: 'contactus-subject',
    EMAIL: 'contactus-email',
    LASTNAME: 'contactus-last-name',
    FIRSTNAME: 'contactus-first-name',
    TITLE: 'contactus-title',
    MICRO_TITLE: 'contactus-micro-title',
    MICRO_DESCRIPTION: 'contactus-micro-description',
  },
  BUTTON: {
    SUBMIT: 'button-submit',
    BACK: 'button-back',
    ADD_TO_CART: 'button-add-to-cart',
    SHOW_ALL: 'button-show-all',
    MINIMIZE: 'button-minimize',
  },
  PRODUCT_INFO: {
    DETAILS_AND_CARE: 'product-info-details-and-care',
    DETAILS: 'product-info-details',
    CARE: 'product-info-care',
    SIZE_AND_FIT: 'product-info-size-and-fit',
    SIZE: 'product-info-size',
    FIT: 'product-info-fit',
    SHIPPING_AND_RETURNS: 'product-info-shipping-and-returns',
    RETURNS: 'product-info-fit-returns',
    SHIPPING: 'product-info-shipping',
    CARE_INFO: 'product-info-care-text',
    SHIPPING_INFO: 'product-info-shipping-text',
    COMPOSITION: 'product-info-composition',
    NOT_IN_STOCK: 'product-info-not-in-stock',
  },
  FRONT_PAGE: {
    ENTER_EMAIL: 'frontpage-enter-email',
    NEWSLETTER_TITLE: 'frontpage-newsletter-title',
  },
  FOOTER: {
    PRIVACY: 'footer-privacy',
    TERMS: 'footer-terms',
    CONTACT_US: 'footer-contact-us',
  },
  CART: {
    PRODUCT_INFO: 'cart-product-info',
    QUANTITY: 'cart-quantity',
    PROCEED: 'cart-proceed',
    VERIFY: 'cart-verify',
    DISCOUNT_CODE: 'cart-discount-code',
    DISCOUNT: 'cart-discount',
    SHIPPING: 'cart-shipping',
    REMOVE: 'cart-remove',
    TOTAL: 'cart-total',
    EMPTY: 'cart-empty',
    PRICE: 'cart-price',
  },
  SHARED: { EMPTY_CART: 'shared-empty-cart' },
  DISCOUNT: {
    MISSING: 'discount-missing',
    APPLIED: 'discount-applied',
    INVALID: 'discount-invalid',
    ERROR: 'discount-error',
  },
  COOKIE: {
    COOKIE_INFO_TOP: 'cookie-info-top',
    COOKIE_INFO_END: 'cookie-info-end',
    COOKIE_INFO_LINK: 'cookie-info-link',
    COOKIE_INFO_BUTTON: 'cookie-info-button',
  },
}
