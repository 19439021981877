import React from 'react'
import { withRouter } from 'react-router-dom'
import Button from './Button'
import useContent from '../Hooks/useContent'

function GoBackButton({ children, history, ...props }) {
  const { L, TRANSLATION_KEYS: { BUTTON } } = useContent()
  const goBack = () => {
    history.push('/')
  }

  return (
    <Button {...props} onClick={goBack}>{L(BUTTON.BACK)}</Button>
  )
}

export default withRouter(GoBackButton)
