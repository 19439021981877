import React from 'react'
import styled, { css } from 'styled-components/macro'
import useSkeleton from '../Hooks/useSkeleton'
import ClipLoader from './ClipLoader'

const WIDTH = {
  medium: '250px',
  small: '100px',
  tiny: '50px',
}

const HEIGHT = {
  medium: '40px',
  small: '30px',
  tiny: '30px',
}

const PADDING = {
  small: '5px 0 5px 0',
  tiny: '15px 0 15px 0',
}

const ClickableButton = styled.button`
  cursor: pointer;
  border: 1px solid black;
  font-family: 'sukhumvit set text';
  text-align: center;

  &:active{
    background-color: lightgray;
    box-shadow: 0 1px #666;
    transform: translateY(2px);
  }

  ${({
    color, size, rounded, isLoading,
  }) => css`
    cursor: ${isLoading ? 'inherit' : 'pointer'};
    color: ${color === 'black' ? 'white' : 'black'};
    background-color: ${color || 'transparent'};
    padding: ${PADDING[size]};
    width: ${WIDTH[size]}
    height: ${HEIGHT[size]}
    border-radius: ${rounded ? '30px' : 'inherit'};
  `}
`
function Button({
  children, color, size = 'medium', rounded = false, isLoading = false, ...props
}) {
  const { ContentSkeleton } = useSkeleton()
  return (
    <ContentSkeleton skeletons={[{ width: WIDTH[size], height: HEIGHT[size] }]}>
      <ClickableButton color={color} size={size} rounded={rounded} isLoading={isLoading} {...props}>
        {isLoading
          ? <ClipLoader size="small" />
          : children}
      </ClickableButton>
    </ContentSkeleton>
  )
}

export default Button
