import React from 'react'
import { render, hydrate } from 'react-dom'
import App from './App'

if (!window.localStorage) {
  let storage = {}
  window.localStorage = {
    getItem(key) {
      return storage[key] || null
    },
    setItem(key, value) {
      storage[key] = value
    },
    clear() {
      storage = {}
    },
    removeItem(key) {
      delete storage[key]
    },
  }
}

const rootElement = document.getElementById('root')
if (rootElement.hasChildNodes()) {
  hydrate(
    <App />,
    document.getElementById('root'),
  )
} else {
  render(
    <App />,
    document.getElementById('root'),
  )
}
