import React from 'react'
import styled from 'styled-components/macro'
import Button from '../Components/Button'
import Input from '../Components/Input'
import maxDevice from '../Style/deviceSize'
import useContent from '../Hooks/useContent'
import useSkeleton from '../Hooks/useSkeleton'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  padding-bottom: 200px;
`
const ButtonContainer = styled.section`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
`

const Title = styled.h2`
  font-size: 36px;
  margin: 0 auto;
  margin-bottom: 50px;
  margin-top: 25px;
  text-align: center;
`

const EmailInput = styled(Input)`
  margin: 0 auto;

  @media ${maxDevice.tablet}{
    width: 65%;
  } 
`

function NewsLetter() {
  const { L, TRANSLATION_KEYS: { FRONT_PAGE, BUTTON } } = useContent()
  const { ContentSkeleton } = useSkeleton()
  return (
    <Container>
      <Title>
        <ContentSkeleton skeletons={[{ width: 600 }]}>
          {L(FRONT_PAGE.NEWSLETTER_TITLE)}
        </ContentSkeleton>
      </Title>
      <form style={{ textAlign: 'center' }} action="https://formspree.io/mayqrnbr" method="POST">
        <ContentSkeleton skeletons={[{ width: 300 }]}>
          <EmailInput name="NewsLetter" type="email" required placeholder={L(FRONT_PAGE.ENTER_EMAIL)} />
        </ContentSkeleton>
        <ButtonContainer>
          <Button type="submit" value="Send" color="white">
            {L(BUTTON.SUBMIT)}
          </Button>
        </ButtonContainer>
      </form>
    </Container>
  )
}

export default NewsLetter
