import { useState, useContext } from 'react'
import getBackendCommunicator from '../Classes/BackendCommunicator'
import CartContext, { updateDiscountMultiplier } from '../Context/Cart.Context'
import useContent from './useContent'

const backendCommunicator = getBackendCommunicator()

const ATTITUDES = {
  HAPPY: 'happy',
  ANGRY: 'angry',
}

const messageDefaultState = { attitude: '', text: '' }

function happyMessage(key, injections) {
  return { attitude: ATTITUDES.HAPPY, key, injections }
}

function angryMessage(key, injections) {
  return { attitude: ATTITUDES.ANGRY, key, injections }
}

function useDiscount() {
  const { TRANSLATION_KEYS: { DISCOUNT } } = useContent()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(messageDefaultState)
  const [, cartDispatch] = useContext(CartContext)

  const removeDiscount = () => {
    updateDiscountMultiplier({ dispatch: cartDispatch, discountCode: '', discountMultiplier: 1 })
    setMessage(messageDefaultState)
  }

  const getDiscount = async (discountCode) => {
    if (!discountCode) {
      setMessage(angryMessage(DISCOUNT.MISSING))
      return
    }

    setLoading(true)
    try {
      const result = await backendCommunicator.getDiscount(discountCode)
      if (result.approved) {
        setMessage(happyMessage(DISCOUNT.APPLIED, [Math.round((1 - result.multiplier) * 100)]))
        updateDiscountMultiplier({ dispatch: cartDispatch, discountCode, discountMultiplier: Number(result.multiplier) })
      } else {
        setMessage(angryMessage(DISCOUNT.INVALID))
      }
    } catch (error) {
      console.error('error', error)
      setMessage(angryMessage(DISCOUNT.ERROR))
    }
    setLoading(false)
  }

  return {
    loading, message, getDiscount, removeDiscount,
  }
}

export { ATTITUDES }
export default useDiscount
