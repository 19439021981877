import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import ProductCard from '../Components/ProductCard'
import Button from '../Components/Button'
import createProductLink from '../Utils/createProductLink'
import useContent from '../Hooks/useContent'
import { LoadingState } from '../Context/Content.Context'
import maxDevice from '../Style/deviceSize'

const MAX_CARDS_TO_SHOW = 30

const Container = styled.section`
  display: flex;
  flex-direction: column;
  /* padding-bottom: 100px; */
  border-bottom: 0.5px solid lightgray;
  @media ${maxDevice.tablet} { 
    background-color: #F7F7F7;
  }
`

const InfoText = styled.h2`
  margin: 0 auto;
  text-align: center;
`

const ButtonContainer = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
`

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

function ProductList() {
  const {
    products, loadingState, L, TRANSLATION_KEYS: { BUTTON },
  } = useContent()
  const [allCards, setAllCards] = useState([])

  const [cardsToShow, setCardsToShow] = useState([])
  const [showAll, setShowAll] = useState(false)

  useEffect(() => {
    if (showAll) {
      setCardsToShow(allCards)
      return
    }
    setCardsToShow(allCards.slice(0, MAX_CARDS_TO_SHOW))
  }, [showAll, allCards])

  useEffect(() => {
    if (products) {
      setAllCards(products)
    }
  }, [products])

  if (loadingState === LoadingState.ERROR) {
    return (
      <Container>
        <InfoText>
          {'Products can\'t be found right now.'}
          <br />
          <br />
          Please come back later
        </InfoText>
      </Container>
    )
  }
  return (
    <Container>
      <>
        <CardWrapper>
          {loadingState === LoadingState.LOADING && Array(25).fill(null).map((_, index) => <ProductCard key={`shimmer-${index}`} />)}
          {loadingState === LoadingState.DONE && cardsToShow.length && (
            cardsToShow.map(({
              type, id, name, price, imageUrls,
            }, index) => (
              <ProductCard
                link={createProductLink(type, id)}
                name={name}
                price={price}
                imageUrl={imageUrls[0]}
                hoverImageUrl={imageUrls[2]}
                key={`productList-${id}`}
                index={index}
              />
            ))
          )}
        </CardWrapper>
        {allCards.length > MAX_CARDS_TO_SHOW && (
          <ButtonContainer>
            <Button color="black" onClick={() => setShowAll(!showAll)}>
              {showAll ? L(BUTTON?.MINIMIZE) : L(BUTTON?.SHOW_ALL)}
            </Button>
          </ButtonContainer>
        )}
      </>
    </Container>
  )
}

export default ProductList
