import React from 'react'
import styled from 'styled-components/macro'

const InputField = styled.textarea`
  margin: 0 auto;
  width: 385px;
  border: 1px solid lightgray;
  text-align: left;
  padding-bottom: 10px;
  margin-bottom: 30px;
  font-size: 13px;
  resize:none;

  &:focus {
    &::-webkit-input-placeholder {
      color: transparent;
      -webkit-transition: color 0.2s ease;
      transition: color 0.2s ease;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: transparent;
      -webkit-transition: color 0.2s ease;
      transition: color 0.2s ease;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: transparent;
      -webkit-transition: color 0.2s ease;
      transition: color 0.2s ease;
    }

    &:-ms-input-placeholder {
      color: transparent;
      -webkit-transition: color 0.2s ease;
      transition: color 0.2s ease;
    }
  }
`

function InputBox({ children, ...props }) {
  return (
    <InputField {...props}>{children}</InputField>
  )
}

export default InputBox
