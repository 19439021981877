import React, { useRef, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useMediaQuery } from 'react-responsive'
import { minDevice } from '../Style/deviceSize'

const StaticContainer = styled.div`
  position: relative;
`

const ClickableDiv = styled.div`
  cursor: pointer;
`

const Overlay = styled.div`
  position: fixed; /* Sit on top of the page content */
  // display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: background-color 1s linear;
  z-index: 100; /* Specify a stack order in case you're using a different order for other elements */
  @media ${minDevice.tablet} {
    background-color: rgba(0,0,0,0.5);
  }
`

const MenuContainer = styled.div`
  /* box-shadow: 0 0 0 100vw rgba(0,0,0,0.65); */
  position: fixed;
  background-color: white;
  display: inline-block;
  text-decoration: none;
  border-radius: 2px;
  padding: 20px 20px 0px 20px;
  height: 100vh;
  /* width: 50vh; */
  width: ${(({ menuWidth }) => menuWidth)};
  right: 0;
  top: 0; 
  overflow-y: scroll;
  transition: width 0.3s;
  border-left: 2px solid black;
`

function getWidth(isBiggerThanTablet) {
  return isBiggerThanTablet
    ? '700px'
    : '100%'
}

function Menu({
  history, children, menuContent, hideOnRoute, locked, showMenu, setShowMenu,
}) {
  const menuRef = useRef()
  const [menuVisible, setMenuVisible] = useState(false)

  const isBiggerThanTablet = useMediaQuery({
    query: minDevice.tablet,
  })
  const [menuWidth, setMenuWidth] = useState('0px')

  useEffect(() => history.listen(() => {
    if (hideOnRoute) setShowMenu(false)
  }), [setShowMenu, hideOnRoute, history])
  useEffect(() => {
    if (!showMenu) return undefined
    const handleClick = (e) => {
      if (!menuRef.current) return
      if (menuRef.current.contains(e.target)) return
      setShowMenu(false)
    }
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [setShowMenu, showMenu])

  useEffect(() => {
    if (showMenu) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'unset'
    return () => { document.body.style.overflow = 'unset' }
  }, [showMenu])

  useEffect(() => {
    let timeout
    if (showMenu) {
      setMenuVisible(true)
      timeout = setTimeout(() => { setMenuWidth(getWidth(isBiggerThanTablet)) }, 0)
    } else {
      setMenuWidth('0px')
      timeout = setTimeout(() => setMenuVisible(false), 300)
    }
    return () => clearTimeout(timeout)
  }, [isBiggerThanTablet, showMenu])
  return (
    <StaticContainer>
      {locked
        ? <div>{children}</div>
        : (
          <ClickableDiv
            role="button"
            tabIndex={0}
            onClick={() => setShowMenu(!showMenu)}
          >
            {children}
          </ClickableDiv>
        )}
      {menuVisible && (
        <Overlay>
          <MenuContainer
            ref={menuRef}
            menuWidth={menuWidth}
          >
            {menuContent}
          </MenuContainer>
        </Overlay>
      )}
    </StaticContainer>
  )
}

export default withRouter(Menu)
