/**
 * Injects/replaces parameters in place of placeholder template variables
 * Example: application/{param1}/{param2} -> applicaiton/test1/test2
 *
 * @param {string} url
 * @param {object} parameters Flat object with parameters to replace matches with
 * @returns {string} a new url with the query paremeters inserted
 */
function urlWithQueryParameters(url, parameters) {
  return url.replace(/\{([a-z0-9]+)\}/gi, (match, paramKey) => {
    if (!parameters[paramKey]) {
      console.error(`InjectParams: Parameter ${paramKey} is undefined`)
      console.error('This is the input:', url)
      console.error('This is the received params:', parameters)
      throw Error(`InjectParams: Parameter ${paramKey} is undefined`)
    }

    return parameters[paramKey]
  })
}

/**
 *
 * @param {Object} options
 * @param {string} options.host
 * @param {import('../Api').Endpoint} options.endpoint
 * @param {Object} options.params
 * @param {string} [options.prefix]
 */
function createUrl({
  host, endpoint, params, prefix = 'https://',
}) {
  const queryTail = ''

  let url = `${prefix}${host}/${endpoint.path}`
  if (endpoint.query || queryTail) url = `${url}?${endpoint.query}${queryTail}`

  return urlWithQueryParameters(url, params)
}

export default createUrl
