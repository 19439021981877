import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

const Cross = styled.a`
  border-width: 0;
  background-color: transparent;
  position: relative;

  width: 32px;
  height: 32px;
  opacity: 0.7;
  cursor: pointer;
  
  :hover {
    opacity: 1;
  }
  :before, :after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 35px;
    width: 2px;
    background-color: black;
  }
  :before {
    transform: rotate(45deg);
  }
  :after {
    transform: rotate(-45deg);
  }
`

function CrossButton({ history, onClick: outerOnClick, ...props }) {
  const onClick = () => {
    if (outerOnClick) return outerOnClick()
    return history.push('/')
  }

  return <Cross onClick={onClick} {...props} />
}

export default withRouter(CrossButton)
