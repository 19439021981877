import React from 'react'
import { Helmet } from 'react-helmet'

const BRAND = 'Gartners'
const CURRENCY = 'SEK'

function generateProductJsonLd({
  id, name, description, url, imageUrl, price, inStock,
}) {
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'Product',
    productID: String(id),
    name,
    description,
    url,
    image: imageUrl,
    brand: BRAND,
    offers: [
      {
        '@type': 'Offer',
        price: price.toFixed(2),
        priceCurrency: CURRENCY,
        itemCondition: 'https://schema.org/NewCondition',
        availability: inStock ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
      },
    ],
  })
}

function Microdata({
  title = 'Gartners',
  imageUrl = `https://${process.env.REACT_APP_FRONTEND_HOST}/logoSmall.png`,
  description,
  url,
  product,
}) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:secure_url" content={imageUrl} />
      <meta property="fb:app_id" content={String(process.env.REACT_APP_FACEBOOK_APP_ID)} />
      {description && <meta property="og:description" content={description} />}
      {url && <meta property="og:url" content={url} />}
      {product && (
        <Helmet>
          <meta property="product:category" content={product.category} />
          <meta property="product:availability" content={product.inStock === false ? 'out of stock' : 'in stock'} />
          <meta property="product:condition" content="new" />
          <meta property="product:price:amount" content={product.price.toFixed(2)} />
          <meta property="product:price:currency" content={CURRENCY} />
          <meta property="product:retailer_item_id" content={String(product.id)} />
          <meta property="product:item_group_id" content={String(product.id)} />
          <script type="application/ld+json">
            {generateProductJsonLd({
              id: product.id,
              name: product.name,
              price: product.price,
              inStock: product.inStock,
              description,
              url,
              imageUrl,
            })}
          </script>
        </Helmet>
      )}
    </Helmet>
  )
}

export default Microdata
