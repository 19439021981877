import React from 'react'
import styled from 'styled-components'

const Cell = styled.div`
  min-width: ${({ width }) => (width || 'inherit')};
  text-align: ${({ textAlign }) => (textAlign || 'inherit')};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const BottomRow = styled(Row)`
  display: flex;
  justify-content: flex-end;
  font-size: ${({ minified }) => (minified ? '14px' : '18px')};
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bolder;
  font-family: 'sukhumvit set bold';
`

const SummaryText = styled.p`
  display: flex;
  justify-content: space-between;
  margin: 0;
  &:nth-of-type(1) {
    margin-top: 10px;
  }
`

const Left = styled.b`
  font-family: 'sukhumvit set bold';
  float: left;
  padding-right:12px;
`

function CartTotal({ minified, cartState, discountedAmount, totalPrice, CART, L }) {
  return (
    <BottomRow minified={minified}>
      <Cell width="30%">
        {cartState.discountMultiplier !== 1
          && (
            <SummaryText>
              <Left>{`${L(CART.DISCOUNT)} `}</Left>
              {`-${discountedAmount.toFixed(2)} kr`}
            </SummaryText>
          )}
        <SummaryText>
          <Left>{`${L(CART.SHIPPING)} `}</Left>
    0.00 kr
  </SummaryText>
        <SummaryText>
          <Left>{`${L(CART.TOTAL)} `}</Left>
          {`${totalPrice.toFixed(2)} kr`}
        </SummaryText>
      </Cell>
    </BottomRow>
  )
}
export default CartTotal