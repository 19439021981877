import React, {
  useContext, useState, useEffect, useRef,
} from 'react'
import { Link, withRouter } from 'react-router-dom'
import useReactRouter from 'use-react-router'
import styled from 'styled-components/macro'
import Button from './Button'
import { ReactComponent as ShoppingCartImage } from '../Svg/shopping-cart.svg'
import CartContext, { getTotalQuantity, calculateTotalPrice } from '../Context/Cart.Context'
import Menu from './Menu'
import CartItems from './CartItems'
import maxDevice from '../Style/deviceSize'
import useContent from '../Hooks/useContent'
import CartTotal from './CartTotal'
import CartDiscount from './CartDiscount'

const CartContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  
`

const CartNumber = styled.span`
  border: solid black 1px;
  background: white;
  color: black;
  margin-left: -8px;
  border-radius: 9px;
  position: absolute;
  user-select: none;
  font-size: 12px;
  padding: 0 5px;

  @media ${maxDevice.mobileL} {
    font-size: 10px;
    padding: 1px 4px 0 4px;
  }
`

const ShoppingCartSvg = styled(ShoppingCartImage)`
  width: 35px;
  height: 35px;

  @media ${maxDevice.tablet}{
    height: 25px;
    width: 25px;
  }
`
const MenuContainer = styled.div`
  min-width: 295px;
`

const EndArea = styled.div`
border-radius: 2px;
background-color: white;
padding-bottom: 12px;
width: 100%;
`

const ButtonContainer = styled.section`
  display: flex;
  justify-content: center;
  padding-top:12px;
`

function Cart() {
  const [cartState] = useContext(CartContext)
  const { products, L, TRANSLATION_KEYS: { CART } } = useContent()
  const { location } = useReactRouter()
  const [menuLocked, setMenuLocked] = useState(true)
  const [showMenu, setShowMenu] = useState(false)
  const [productsToShow, setProductsToShow] = useState([])
  const { totalPrice, discountedAmount } = calculateTotalPrice(productsToShow, cartState.discountMultiplier)
  const previousTotalProductAmount = useRef(null)

  useEffect(() => {
    if (previousTotalProductAmount.current === null) {
      previousTotalProductAmount.current = cartState.totalProductAmount
      return
    }
    if (previousTotalProductAmount.current === cartState.totalProductAmount) return
    setShowMenu(true)
  }, [cartState])

  useEffect(() => {
    if (products && cartState.products.length) {
      setProductsToShow(cartState.products.map(({ id: cartProductId, quantity }) => ({
        ...products.find(({ id: contentProductId }) => contentProductId === cartProductId),
        quantity,
      })))
    } else {
      setProductsToShow([])
    }
  }, [products, cartState])

  useEffect(() => {
    const isOnCheckout = location.pathname === '/checkout'
    if (!isOnCheckout) {
      setMenuLocked(false)
      return
    }
    setMenuLocked(true)
  }, [location, cartState])

  const renderMenuContent = () => (
    <MenuContainer>
      <CartItems setShowMenu={setShowMenu} minified interactive />
      {cartState.products.length > 0 && (
        <EndArea>
          <CartDiscount cartState={cartState} CART={CART} L={L} />
          <CartTotal minified cartState={cartState} discountedAmount={discountedAmount} totalPrice={totalPrice} CART={CART} L={L} />
          <ButtonContainer>
            <Link to="/checkout">
              <Button color="black">{L(CART.PROCEED)}</Button>
            </Link>
          </ButtonContainer>
        </EndArea>
      )}
    </MenuContainer>
  )

  return (
    <CartContainer>
      <Menu setShowMenu={setShowMenu} showMenu={showMenu} menuContent={renderMenuContent()} hideOnRoute locked={menuLocked}>
        <ShoppingCartSvg />
        <CartNumber>{getTotalQuantity(cartState.products)}</CartNumber>
      </Menu>
    </CartContainer>
  )
}

export default withRouter(Cart)
