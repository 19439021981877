import React from 'react'

const getSupportedLanguage = (locale) => {
  switch (locale) {
    case 'sv-SE':
      return 'sv'
    default:
      return 'en'
  }
}

const defaultState = {
  /** @type {string} */
  language: '',
  /** @type {(language: string) => void} */
  switchLangauge: undefined,
}

function useLanguageProviderState() {
  const [languageCode, setLanguageCode] = React.useState(
    window.localStorage.getItem('appUILanguage')
    || getSupportedLanguage('sv-SE'), // Default to Swedish if never explicitly changed
  )

  React.useLayoutEffect(() => {
    const selectedLanguage = window.localStorage.getItem('appUILanguage')
    if (selectedLanguage) setLanguageCode(selectedLanguage)
  }, [languageCode])

  const switchLanguage = (langauge) => {
    setLanguageCode(langauge)
    window.localStorage.setItem('appUILanguage', langauge)
  }

  return {
    languageCode,
    switchLanguage,
  }
}

const LanguageContext = React.createContext(defaultState)

export function LanguageProvider({ children }) {
  const state = useLanguageProviderState()

  return (
    <LanguageContext.Provider value={state}>
      {children}
    </LanguageContext.Provider>
  )
}

export default LanguageContext
