import React from 'react'
import useSkeleton from '../Hooks/useSkeleton'

function Markdown({
  children,
  Heading1 = 'h1',
  Heading3 = 'h3',
  Paragraph = 'p',
}) {
  const { ContentSkeleton } = useSkeleton()
  const texts = children
    .split('\n')
    .filter(Boolean)

  const Element = ({ children: text }) => {
    const type = text.split(' ')[0]
    switch (type) {
      case '#':
        return <Heading1>{text.replace(type, '')}</Heading1>
      case '###':
        return <Heading3>{text.replace(type, '')}</Heading3>
      default:
        return <Paragraph>{text}</Paragraph>
    }
  }

  return (
    <ContentSkeleton
      skeletons={[
        { height: 40, width: 300 },
        { height: 30, width: 450, marginTop: 40 },
        { height: 100, marginTop: 30 },
        { height: 100, marginTop: 20 },
        { height: 100, marginTop: 20 },
        { height: 100, marginTop: 20 },
      ]}
    >
      {texts.map((text, index) => <Element key={`markdown-${index}-${text[0]}`}>{text}</Element>)}
    </ContentSkeleton>
  )
}

export default Markdown
