import send from './Utils/send'

let backendCommunicator

function BackendCommunicator() {
  /** @private */
  const composeOptions = (options) => ({
    host: process.env.REACT_APP_BACKEND_API_HOST,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_BACKEND_API_KEY,
    },
    ...options,
  })

  const getContent = () => send(
    composeOptions({
      endpoint: {
        path: 'getdata',
        query: '',
        method: 'GET',
      },
    }),
  )

  const getDiscount = (discountCode) => send(
    composeOptions({
      endpoint: {
        path: `get-discount/${discountCode}`,
        query: '',
        method: 'GET',
      },
    }),
  )

  const createKlarnaPayment = (languageCode, products, discountCode) => send(
    composeOptions({
      endpoint: {
        path: 'klarna/create',
        query: '',
        method: 'POST',
      },
      body: { languageCode, products, discountCode },
    }),
  )

  const submitOrder = (orderId) => send(
    composeOptions({
      endpoint: {
        path: `submit-order/${orderId}`,
        query: '',
        method: 'POST',
      },
    }),
  )

  return {
    getContent, getDiscount, createKlarnaPayment, submitOrder,
  }
}

function getBackendCommunicator() {
  if (!backendCommunicator) {
    backendCommunicator = BackendCommunicator()
  }
  return backendCommunicator
}

export default getBackendCommunicator
