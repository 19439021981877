import React, { useState, useContext } from 'react'
import styled from 'styled-components/macro'
import { ReactComponent as SwedenFlag } from '../Svg/sweeden.svg'
import { ReactComponent as UkFlag } from '../Svg/uk.svg'
import LanguageContext from '../Context/Language.Context'
import maxDevice from '../Style/deviceSize'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const Language = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-right: 4px;
  cursor: pointer;
  :not(:first-of-type):before{
    padding-right: 5px;
    content: '|'
  }
  @media ${maxDevice.mobileL} {
    font-size: 12px;
  }
`

const Border = styled.div`
  display: flex;
  flex-direction: row;
`

const languageOptions = [
  { language: 'Sv', languageCode: 'sv', flag: SwedenFlag },
  { language: 'En', languageCode: 'en', flag: UkFlag },
]

function LanguageSelector() {
  const { switchLanguage } = useContext(LanguageContext)
  const [LanguageFlag, setLanguageFlag] = useState(languageOptions[0].flag)
  const updateLanguage = (languageOption) => {
    switchLanguage(languageOption.languageCode)
    setLanguageFlag(languageOption.flag)
  }

  const renderFlag = (Flag) => {
    if (!Flag) return null
    const StyledFlag = styled(Flag)`
      width: 20px;
      height: 20px;
      @media ${maxDevice.mobileL} {
        width: 12px;
        height: 12px;
      }
    `
    return <StyledFlag />
  }

  return (
    <Wrapper>
      <Border>
        {languageOptions.map((languageOption) => (
          <Language
            onClick={() => updateLanguage(languageOption)}
            key={languageOption.language}
          >
            {languageOption.language}
          </Language>
        ))}
      </Border>
      {renderFlag(LanguageFlag)}
    </Wrapper>
  )
}

export default LanguageSelector
