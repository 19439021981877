import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import Button from './Button'
import useContent from '../Hooks/useContent'

const HIDE_COOKIES_KEY = 'HideCookiesDisplay'

const Display = styled.div`
  border-top: 1px solid lightgray;
  padding: 2%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  background-color: white;
`
const Text = styled.div`
  margin-bottom: 12px;
  font-size: 12px;
`
const StyledLink = styled(Link)`
  text-decoration: underline;
  color: black;
`

function CookiesDisplay() {
  const [hideCookiesDisplay, setHideCookiesDisplay] = useState(window.location.hostname === 'localhostt' || window.localStorage.getItem(HIDE_COOKIES_KEY) === 'true')
  const { L, TRANSLATION_KEYS: { COOKIE } } = useContent()
  const hide = () => {
    setHideCookiesDisplay(true)
    window.localStorage.setItem(HIDE_COOKIES_KEY, true)
  }

  if (hideCookiesDisplay) return null
  return (
    hideCookiesDisplay ? null : (
      <Display>
        <Text>
          {`${L(COOKIE.COOKIE_INFO_TOP)} `}
          <StyledLink to="/privacy">{L(COOKIE.COOKIE_INFO_LINK)}</StyledLink>
          {` ${L(COOKIE.COOKIE_INFO_END)}`}
        </Text>
        <Button onClick={hide}>{L(COOKIE.COOKIE_INFO_BUTTON)}</Button>
      </Display>
    )
  )
}

export default CookiesDisplay
