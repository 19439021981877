import React from 'react'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import useContent from '../Hooks/useContent'
import { LoadingState } from '../Context/Content.Context'

const StyledSkeleton = styled(Skeleton)`
  margin-top: ${(({ marginTop }) => (marginTop ? `${marginTop}px` : 'initial'))};
  margin-right: ${(({ marginRight }) => (marginRight ? `${marginRight}px` : 'initial'))};
`

function ContentSkeleton({ children, skeletons = [{}] } = {}) {
  const { loadingState } = useContent()

  if (loadingState !== LoadingState.DONE) {
    return skeletons.map((args, index) => <StyledSkeleton {...args} key={`skeleton-${index}-${JSON.stringify(args)} `} />)
  }
  return children || null
}

export default ContentSkeleton
