/**
 * @param {{
 *   en: {
 *     products: {
 *       Scarf: [
 *         { name, id, type, price, discountPrice }
 *       ]
 *     }
 *   },
 * }} content
 */
export default function extractFromContent(content, language) {
  const combinedProductList = []
  Object.values(content[language].products).forEach((productList) => {
    productList.forEach((product) => {
      combinedProductList.push(product)
    })
  })
  return {
    products: combinedProductList,
    translationKeys: content[language].translationKeys,
  }
}
