import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import useReactRouter from 'use-react-router'
import Button from './Button'
import CartContext, { updateProduct, increment, decrement } from '../Context/Cart.Context'
import useContent from '../Hooks/useContent'
import maxDevice from '../Style/deviceSize'
import { ReactComponent as Increase } from '../Svg/c-add.svg'
import { ReactComponent as Decrease } from '../Svg/c-delete.svg'
import CrossButton from './CrossButton'

const Cell = styled.div`
  min-width: ${({ width }) => (width || 'inherit')};
  text-align: ${({ textAlign }) => (textAlign || 'inherit')};
`

const PictureCell = styled(Cell)`
  margin-left: 20px;
  
  min-width:100px;
  min-height:100px;

  @media ${maxDevice.tablet}{
  margin-left:0px;
} 
`

const ContentCell = styled(Cell)`
  padding-top: 15px;
  font-size: ${({ minified }) => (minified ? '12px' : '18px')};
  font-weight: ${({ minified }) => (minified ? 'bold' : 'bold')};
`

const ProductCell = styled(ContentCell)`
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  justify-content: flex-start;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const ContentRow = styled(Row)`
  padding-top:12px;
`

const Table = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color:white;
`

const PictureArea = styled.img`
  width:  ${({ minified }) => (minified ? '100px' : '170px')};
  height: ${({ minified }) => (minified ? '100px' : '212.5px')};
  padding-right: 12px;
`

const ShortProductInfo = styled.div`
  margin: ${({ minified }) => (minified ? '0 0 5px 5px;' : '0px')};
  font-size: ${({ minified }) => (minified ? '10px' : '18px')};
  font-weight: ${({ minified }) => (minified ? 'bold' : 'bold')};
  display:flex;
  justify-content:space-between;
`

const ProductTitle = styled.h5`
  font-size: ${({ minified }) => (minified ? '12px' : '18px')};
  margin-top: ${({ minified }) => (minified ? '0px' : '10px')};
  margin-bottom: ${({ minified }) => (minified ? '0px' : '0px')};
`

const QuantityArea = styled.div`
display: flex;
justify-content: space-around;
align-items:center;
width:20%;
`

const StyledCrossButton = styled(CrossButton)`
  margin: 14px 19px 0 auto;
`

const Quantity = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;   
  -webkit-appearance: none; 
  text-align: center;
  width: 25px;
  height: 15px;
  font-size: 14px;

  -moz-appearance: 'textfield'; /* Firefox */
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }  
`

const EmptyText = styled.h2`
  margin: 30px auto;
  width:100%;
  text-align:center;
`

function CartItems({ minified, interactive, setShowMenu }) {
  const { products, L, TRANSLATION_KEYS: { CART } } = useContent()
  const [cartState, cartDispatch] = useContext(CartContext)
  const [productsToShow, setProductsToShow] = useState([])
  const { location } = useReactRouter()

  useEffect(() => {
    if (products && cartState.products.length) {
      setProductsToShow(cartState.products.map(({ id: cartProductId, quantity }) => ({
        ...products.find(({ id: contentProductId }) => contentProductId === cartProductId),
        quantity,
      })))
    } else {
      setProductsToShow([])
    }
  }, [products, cartState])

  const onDecrement = ({ id }) => {
    decrement({ dispatch: cartDispatch, id })
  }
  const onIncrement = ({ id }) => {
    increment({ dispatch: cartDispatch, id })
  }

  const isOnCheckout = location.pathname === '/checkout'

  return (
    <Table>
      {isOnCheckout ? null : <StyledCrossButton onClick={() => setShowMenu(false)} />}
      {!productsToShow.length ? <EmptyText>{L(CART.EMPTY)}</EmptyText>
        : (
          <>
            {productsToShow
              .map(({
                id, name, price, quantity, imageUrls,
              }) => (
                <ContentRow minified={minified} key={`CartItem-${id}`}>
                  <PictureCell>
                    <PictureArea minified={minified} src={imageUrls[0]} />
                  </PictureCell>

                  <ProductCell minified={minified}>
                    <ProductTitle minified={minified}>{name}</ProductTitle>

                    <ContentCell minified={minified} textAlign="left"><ProductTitle minified={minified}>{`${price.toFixed(2)} kr`}</ProductTitle></ContentCell>
                    <ContentCell minified={minified}>
                      <ShortProductInfo>
                        {interactive
                          ? (
                            <QuantityArea>
                              <Decrease onClick={() => onDecrement({ id })} />
                              <Quantity>
                                {quantity}

                              </Quantity>
                              <Increase onClick={() => onIncrement({ id })} />
                            </QuantityArea>
                          )
                          : quantity}
                        {interactive && (
                        <Button
                          size="small"
                          onClick={() => {
                            updateProduct({ dispatch: cartDispatch, id, quantity: 0 })
                          }}
                        >
                          {L(CART.REMOVE)}
                        </Button>
                        )}
                      </ShortProductInfo>
                    </ContentCell>
                  </ProductCell>

                </ContentRow>
              ))}
          </>
        )}
    </Table>
  )
}

export default CartItems
