import React from 'react'
import getBackendCommunicator from '../Classes/BackendCommunicator'

const backendCommunicator = getBackendCommunicator()

/** @enum {string} */
export const LoadingState = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  DONE: 'DONE',
  ERROR: 'ERROR',
}

const defaultState = {
  /** @type {LoadingState} */
  loadingState: LoadingState.IDLE, // LOADING DONE ERROR
  /** @type {Object<string, any>} */
  content: {},
  /** @type {Error | null} */
  error: null,
}

const Actions = {
  FetchBegin: 'FETCH_BEGIN',
  FetchComplete: 'FETCH_COMPLETE',
  FetchError: 'FETCH_ERROR',
}

function reducer(state, action) {
  switch (action.type) {
    case Actions.FetchBegin: {
      return {
        ...state,
        loadingState: 'LOADING',
        error: null,
      }
    }
    case Actions.FetchComplete: {
      return {
        ...state,
        loadingState: 'DONE',
        content: action.payload.content,
        error: null,
      }
    }
    case Actions.FetchError: {
      return {
        ...state,
        loadingState: 'ERROR',
        error: action.payload.error,
      }
    }
    default: {
      return state
    }
  }
}

function useContentProviderState(getContent) {
  const [state, dispatch] = React.useReducer(reducer, defaultState)

  React.useEffect(() => {
    async function fetchContent() {
      dispatch({ type: Actions.FetchBegin })
      try {
        const content = await getContent()

        dispatch({
          type: Actions.FetchComplete,
          payload: { content },
        })
      } catch (error) {
        dispatch({ type: Actions.FetchError, payload: { error } })
      }
    }

    fetchContent()
  }, [getContent])

  return state
}

const ContentContext = React.createContext(defaultState)

export function ContentProvider({ children }) {
  const state = useContentProviderState(backendCommunicator.getContent)

  return (
    <ContentContext.Provider value={state}>
      {children}
    </ContentContext.Provider>
  )
}

export default ContentContext
