import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { ReactComponent as ArrowUp } from '../Svg/arrow-up.svg'
import { ReactComponent as ArrowDown } from '../Svg/arrow-down.svg'

const Container = styled.div`
  border-bottom: 1px solid lightgray;
`

const ClickableArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

const Title = styled.h4`
  text-align: left;
  margin: 7px 0;
  font-size: 18px;
`

const Text = styled.p`
  margin-top: 0px;
  text-align: left;
`
const TextField = styled.div`
  overflow: hidden;
  ${({ expanded }) => css`
    max-height: ${expanded ? '500px' : '0'};
    transition: ${expanded ? 'max-height 1s ease-in-out' : 'max-height 0.7s cubic-bezier(0, 1, 0, 1)'};
  `}
`

function ExpandableText({ children, content }) {
  const [showContent, setShowContent] = useState(false)

  return (
    <Container>
      <ClickableArea onClick={() => setShowContent(!showContent)}>
        <Title>{children}</Title>
        {showContent ? <ArrowUp /> : <ArrowDown />}
      </ClickableArea>
      <TextField expanded={showContent}>
        {Object.entries(content).map(([boldText, text]) => (
          <Text key={boldText}>
            <b>{`${boldText}: `}</b>
            {text}
          </Text>
        ))}
      </TextField>
    </Container>
  )
}

export default ExpandableText
