import React from 'react'
import styled from 'styled-components/macro'
import ProductList from '../PageSections/ProductList'
import NewsLetter from '../PageSections/NewsLetter'
import Microdata from '../Components/Microdata'
import useContent from '../Hooks/useContent'

const Container = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`

function Homepage() {
  const { L, TRANSLATION_KEYS: { HOMEPAGE } } = useContent()
  return (
    <Container>
      <Microdata
        title={L(HOMEPAGE.MICRO_TITLE)}
        description={L(HOMEPAGE.MICRO_DESCRIPTION)}
        url={`https://${process.env.REACT_APP_FRONTEND_HOST}`}
      />
      <ProductList />
      <NewsLetter />
    </Container>
  )
}

export default Homepage
